<template>
  <LoadingScreen v-if="isLoading" />
  <RouterView v-else />
</template>

<script lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
import {
  defineComponent,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
} from 'vue'
import { RouterView, useRouter } from 'vue-router'
import LoadingScreen from '@/components/LoadingScreen/LoadingScreen.vue'
import { useAppLoadStore } from '@/libs/appLoadStore'
import { storeUTMParams } from '@/libs/events'
import { useSplitAuth } from '@/libs/splitAuth'
import { getLogger } from './libs/logging'

export default defineComponent({
  components: {
    RouterView,
    LoadingScreen,
  },
  setup() {
    useSplitAuth()

    const auth0 = useAuth0()
    const router = useRouter()
    const appLoadStore = useAppLoadStore()
    const isLoading = computed(() => appLoadStore.isLoading)
    const { endLoading } = appLoadStore

    storeUTMParams()

    watch(
      auth0.error,
      async (error) => {
        if (error) {
          getLogger().error('Auth0 error occurred', error)
          await router.push({ name: '#error' })
        }
      },
      {
        immediate: true,
      }
    )

    // hide LoadingScreen when page is restored from bfcache
    const handlePageTransition = (event: PageTransitionEvent) => {
      if (event.persisted) {
        endLoading()
      }
    }

    onMounted(() => {
      window.addEventListener('pageshow', handlePageTransition)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('pageshow', handlePageTransition)
    })

    return {
      isLoading,
    }
  },
})
</script>
