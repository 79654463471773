import { datadogRum } from '@datadog/browser-rum'
import { MonitoringEvent } from '@/types/events'
import { getAppConfig } from './config'
import { getLogger } from './logging'

/**
 * Trivial wrapper around rum events so we can easily swap out
 * to a different events backend if needed.
 * @param event
 * @param commonData
 * @param commonData.entityId
 */
async function sendMonitoringEvent(
  event: MonitoringEvent,
  commonData: { entityId?: string }
) {
  const data = {
    ...commonData,
    ...getUTMFromSessionStorage(),
    ...event.data,
  }

  getLogger().info('Sending monitoring event to Datadog', event.event, data)
  datadogRum.addAction(event.event, data)

  getLogger().info(
    'Sending monitoring event to Event Service',
    event.event,
    data
  )
  await sendMonitoringEventToEventService(event, data)
}

/**
 *
 * @param event
 */
export function sendNonLoggedInMonitoringEvent(event: MonitoringEvent) {
  void sendMonitoringEvent(event, {})
}

/**
 *
 * @param event
 * @param entityId
 */
export function sendLoggedInMonitoringEvent(
  event: MonitoringEvent,
  entityId: string
) {
  void sendMonitoringEvent(event, { entityId })
}

export const useEventsService = () => {
  return { sendMonitoringEvent }
}

/**
 * Make a request to event service with event and data
 * @param event
 * @param data
 */
async function sendMonitoringEventToEventService(
  event: MonitoringEvent,
  data: any
) {
  const config = getAppConfig()
  const accessToken = config.eventService.apiKey
  // IMPROVEME (SPC-1610) - Send datadog session ID when available
  const sessionIdPlaceholder = '00000000-0000-0000-0000-000000000000'

  await fetch(config.eventService.url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },

    body: JSON.stringify({
      event: event.event,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      data,
      sessionId: sessionIdPlaceholder,
    }),
  })
}

/**
 * Pull UTM parameters from URL and store in local session storage
 * Used to track marketing campaign performance
 * passed to events service
 */
export function storeUTMParams() {
  const utmParams = new URLSearchParams(window.location.search)
  const utmContent = utmParams.get('utm_content')
  const utmMedium = utmParams.get('utm_medium')
  const utmCampaign = utmParams.get('utm_campaign')

  if (utmContent) {
    window.sessionStorage.setItem('utm_content', utmContent)
  }
  if (utmMedium) {
    window.sessionStorage.setItem('utm_medium', utmMedium)
  }
  if (utmCampaign) {
    window.sessionStorage.setItem('utm_campaign', utmCampaign)
  }
}

/**
 * Get UTM parameters from session storage
 */
export function getUTMFromSessionStorage() {
  return {
    utmContent: window.sessionStorage.getItem('utm_content'),
    utmMedium: window.sessionStorage.getItem('utm_medium'),
    utmCampaign: window.sessionStorage.getItem('utm_campaign'),
  }
}
