import { standardScrollBehavior } from '@thyme/libs/src/vue/router'
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

import {
  RouteThemeDefinition,
  RouteThemeQueryParam,
  DefaultTheme,
} from '@/types/theme'
import { AUTH_CALLBACK_NAME, maybeAuthGuard } from './auth'
import { maybeReloadGuard } from './autoupdate'
import { pageTitleHook } from './pageTitle'

const DEFAULT_PAGE_TITLE = 'Thyme Care Connect'

export type Route = RouteRecordRaw & {
  name: string
  meta: {
    noAuthRequired?: boolean
    themeDefinition?: RouteThemeDefinition
    title: string
  }
}

export const routes: Route[] = [
  {
    path: '/',
    name: '#home',
    component: () => import('@/components/HomePage/HomePage.vue'),
    meta: {
      title: DEFAULT_PAGE_TITLE,
    },
  },
  {
    path: '/login-method',
    name: '#loginMethod',
    component: () => import('@/components/LoginMethodPage/LoginMethodPage.vue'),
    meta: {
      noAuthRequired: true,
      title: 'Choose Sign In Method',
    },
  },
  {
    path: '/auth/callback',
    name: AUTH_CALLBACK_NAME,
    component: () => import('@/components/AuthCallback/AuthCallback.vue'),
    meta: {
      // The auth callback page needs to be public
      // https://github.com/auth0/auth0-vue/blob/main/FAQ.md#4-getting-an-infinite-redirect-loop-between-my-application-and-auth0
      noAuthRequired: true,
      title: 'Authenticating...',
    },
  },
  {
    path: '/support',
    name: '#support',
    component: () => import('@/components/GetSupportPage/GetSupportPage.vue'),
    meta: {
      title: 'Get Support',
    },
  },
  {
    path: '/symptoms',
    name: '#symptoms',
    component: () => import('@/components/SymptomIdPage/SymptomIdPage.vue'),
    meta: {
      title: 'Track Symptoms',
    },
  },
  {
    path: '/library',
    name: '#library',
    component: () =>
      import('@/components/LibraryIndexPage/LibraryIndexPage.vue'),
    meta: {
      title: 'Library',
    },
  },
  {
    path: '/library/:resourceSlug',
    name: '#libraryPage',
    component: () =>
      import('@/components/LibraryArticlePage/LibraryArticlePage.vue'),
    props: true,
    meta: {
      title: 'Library',
    },
  },
  {
    path: '/community',
    name: '#communityResources',
    component: () =>
      import('@/components/CommunityResourcesPage/CommunityResourcesPage.vue'),
    meta: {
      title: 'Community Resources',
    },
  },
  // public pages
  {
    path: '/aon/surveys',
    name: '#aonSurveys',
    component: () => import('@/components/SurveyPage/SurveyPage.vue'),
    meta: {
      noAuthRequired: true,
      title: 'AON Survey',
      themeDefinition: {
        themeQueryParams: [RouteThemeQueryParam.PRACTICE],
        default: DefaultTheme.AON,
      },
    },
  },
  {
    path: '/surveys',
    name: '#surveys',
    component: () => import('@/components/SurveyPage/SurveyPage.vue'),
    meta: {
      noAuthRequired: true,
      title: 'Survey',
      themeDefinition: {
        themeQueryParams: [
          RouteThemeQueryParam.PRACTICE,
          RouteThemeQueryParam.CONTRACTING_ENTITY,
        ],
        default: DefaultTheme.DEFAULT,
      },
    },
  },
  {
    path: '/login-help',
    name: '#loginHelp',
    component: () => import('@/components/LoginHelpPage/LoginHelpPage.vue'),
    meta: {
      noAuthRequired: true,
      title: 'Sign In Help',
    },
  },
  {
    path: '/error',
    name: '#error',
    component: () => import('@/components/ErrorPage/ErrorPage.vue'),
    meta: {
      noAuthRequired: true,
      title: 'Error',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '#notFound',
    component: () => import('@/components/NotFoundPage/NotFoundPage.vue'),
    meta: {
      noAuthRequired: true,
      title: 'Page Not Found',
    },
  },
]

/**
 * Creates the TC connect router.
 */
export function getRouter() {
  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior: standardScrollBehavior,
  })

  // eslint-disable-next-line promise/prefer-await-to-callbacks
  router.onError((error: Error, to) => {
    if (
      error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('Importing a module script failed')
    ) {
      window.location.href = to.fullPath
    }
  })

  router.beforeEach(maybeReloadGuard)
  router.beforeEach(maybeAuthGuard)
  router.afterEach(pageTitleHook)
  return router
}

export const router = getRouter()
