import {
  ThemeConfig,
  AppTheme,
  DEFAULT_LOGO_WIDTH,
  TC_PURPLE_LOGO,
  TC_WHITE_LOGO,
} from '@/types/theme/base'

type PracticeThemeConfig = {
  readonly [key: string]: ThemeConfig
}

export const AON_LOGO_WIDTH = 200

const AON_PRACTICE_THEME_CONFIG: PracticeThemeConfig = {
  cbsa: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Cancer & Blood Specialists of Arizona',
        src: '/assets/images/aon/cbsa.png',
        url: {
          href: 'https://www.cancerbloodspecialistsaz.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  cbsg: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Cancer & Blood Specialists of Georgia',
        src: '/assets/images/aon/cbsg.png',
        url: {
          href: 'https://www.cancerbloodspecialistsga.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  cbsn: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Cancer & Blood Specialists of North Virginia',
        src: '/assets/images/aon/cbsn.png',
        url: {
          href: 'https://cancerandbloodspecialistsofnova.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  dho: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Desert Hematology Oncology',
        src: '/assets/images/aon/dho.png',
        url: {
          href: 'https://www.deserthematologyoncology.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  gcc: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Genesis Cancer & Blood Institute',
        src: '/assets/images/aon/gcc.png',
        url: {
          href: 'https://genesiscancerblood.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  gohc: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Georgia Oncology Hematology Consultants',
        src: '/assets/images/aon/gohc.png',
        url: {
          href: 'https://gaoncologyhematology.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  gscbs: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Gem State Cancer & Blood Specialists',
        src: '/assets/images/aon/gscbs.png',
        url: {
          href: 'https://www.gemstatecancerbloodspecialists.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  hccn: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Hematology & Cancer Center of Nevada',
        src: '/assets/images/aon/hccn.png',
        url: {
          href: 'https://www.hccnevada.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  hoc: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Hematology Oncology Center',
        src: '/assets/images/aon/hoc.png',
        url: {
          href: 'https://elyriahoc.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  hemonc: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Hematology Oncology',
        src: '/assets/images/aon/hemonc.png',
        url: {
          href: 'https://www.aoncology.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  hoh: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Heartland Oncology & Hematology',
        src: '/assets/images/aon/hoh.png',
        url: {
          href: 'https://www.heartlandoncology.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  hoi: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Hematology Oncology of Indiana',
        src: '/assets/images/aon/hoi.png',
        url: {
          href: 'https://www.hematologyoncologyin.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  lccc: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Low Country Cancer Care',
        src: '/assets/images/aon/lccc.png',
        url: {
          href: 'https://www.lcccsav.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  mcc: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Messino Cancer Centers',
        src: '/assets/images/aon/mcc.png',
        url: {
          href: 'https://messinocancercenters.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  micc: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Michigan Cancer Specialists',
        src: '/assets/images/aon/micc.png',
        url: {
          href: 'https://www.micancerspecialists.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  oha: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Oncology Hematology Associates',
        src: '/assets/images/aon/oha.png',
        url: {
          href: 'https://ohaclinic.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  ohl: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Oncology Hematology of Loudoun',
        src: '/assets/images/aon/ohl.png',
        url: {
          href: 'https://loudounoncology.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  pano: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Panacea Oncology',
        src: '/assets/images/aon/pano.png',
        url: {
          href: 'https://www.panaceaoncology.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  scc: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Summit Cancer Centers',
        src: '/assets/images/aon/scc.png',
        url: {
          href: 'https://www.summitcancercenters.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  vista: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Vista Oncology',
        src: '/assets/images/aon/vista.png',
        url: {
          href: 'https://www.vista-oncology.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  zcc: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Zang Cancer Center',
        src: '/assets/images/aon/zcc.png',
        url: {
          href: 'https://www.zangcenter.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  ccbd: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Comprehensive Cancer & Blood Disorders',
        src: '/assets/images/aon/ccbd.png',
        url: {
          href: 'https://ccbdmd.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  gwoa: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Georgia Women’s Oncology Associates',
        src: '/assets/images/aon/gwoa.png',
        url: {
          href: 'https://www.gwoa.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  lone: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Lone Star Oncology',
        src: '/assets/images/aon/lone.png',
        url: {
          href: 'https://www.lonestaronc.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  tcu: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Triple Crown Urology',
        src: '/assets/images/aon/tcu.png',
        url: {
          href: 'https://www.triplecrownurology.com',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  flonc: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Florida Oncology',
        src: '/assets/images/aon/flonc.png',
        url: {
          href: 'https://www.floridaonc.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  bho: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Bay Hematology Oncology',
        src: '/assets/images/aon/bho.png',
        url: {
          href: 'https://www.bayhematologyoncology.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  cgcc: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Central Georgia Cancer Care',
        src: '/assets/images/aon/cgcc.png',
        url: {
          href: 'https://centralgacancercare.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  wci: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Woodlands Cancer Institute',
        src: '/assets/images/aon/wci.png',
        url: {
          href: 'https://www.woodlandscancer.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  fwmoh: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'Fort Wayne Medical Oncology & Hematology',
        src: '/assets/images/aon/fwmoh.png',
        url: {
          href: 'https://www.fwmoh.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  macc: {
    theme: AppTheme.WHITE,
    logos: [
      {
        name: 'MidAmerica Cancer Care',
        src: '/assets/images/aon/macc.png',
        url: {
          href: 'https://www.maccsp.com/',
        },
        width: AON_LOGO_WIDTH,
      },
    ],
  },
  tcAonFallback: {
    theme: AppTheme.WHITE,
    logos: [TC_PURPLE_LOGO],
  },
} as const

const TC_PRACTICE_THEME_CONFIG: PracticeThemeConfig = {
  default: {
    theme: AppTheme.PURPLE,
    logos: [TC_WHITE_LOGO],
  },
  astera: {
    theme: AppTheme.WHITE,
    logos: [
      TC_PURPLE_LOGO,
      {
        name: 'Astera Cancer Care',
        src: '/assets/images/logos/astera.png',
        url: {
          href: 'https://asteracancercare.org/',
        },
        width: DEFAULT_LOGO_WIDTH,
      },
    ],
  },
  njcc: {
    theme: AppTheme.WHITE,
    logos: [
      TC_PURPLE_LOGO,
      {
        name: 'New Jersey Cancer Care',
        src: '/assets/images/logos/njcc.png',
        url: {
          href: 'https://njcancercare.com',
        },
        width: DEFAULT_LOGO_WIDTH,
      },
    ],
  },
  rcca: {
    theme: AppTheme.WHITE,
    logos: [
      TC_PURPLE_LOGO,
      {
        name: 'Regional Cancer Care Associates',
        src: '/assets/images/logos/rcca.png',
        url: {
          href: 'https://www.regionalcancercare.org/',
        },
        width: DEFAULT_LOGO_WIDTH,
      },
    ],
  },
  ao: {
    theme: AppTheme.WHITE,
    logos: [
      TC_PURPLE_LOGO,
      {
        name: 'Affiliated Oncologists',
        src: '/assets/images/logos/ao.png',
        url: {
          href: 'https://aocancer.com/',
        },
        width: DEFAULT_LOGO_WIDTH,
      },
    ],
  },
  nycbs: {
    theme: AppTheme.WHITE,
    logos: [
      TC_PURPLE_LOGO,
      {
        name: 'New York Cancer & Blood Specialists',
        src: '/assets/images/logos/nycbs.png',
        url: {
          href: 'https://nycancer.com/',
        },
        width: DEFAULT_LOGO_WIDTH,
      },
    ],
  },
  soha: {
    theme: AppTheme.WHITE,
    logos: [
      TC_PURPLE_LOGO,
      {
        name: 'Southern Oncology Hematology Associates',
        src: '/assets/images/logos/soha.png',
        url: {
          href: 'https://southernoncology.net/',
        },
        width: DEFAULT_LOGO_WIDTH,
      },
    ],
  },
} as const

export const ALL_PRACTICES_THEME_CONFIGS: PracticeThemeConfig = {
  ...AON_PRACTICE_THEME_CONFIG,
  ...TC_PRACTICE_THEME_CONFIG,
} as const
